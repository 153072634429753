<template>

    <div class="panel panel-default ">
      <div class="panel-heading">
        <p class="mb-5 mt-5"><span class="bold">STATUS</span> : {{ !isDarkMode ? 'Dark Mode Enabled 🌙' : 'Light Mode Enabled ☀️' }}</p>
      </div>
      <div class="panel-body">
        <!--Only code you need is this label-->
        <label class="switch" >
          <input type="checkbox" v-model="isDarkMode"  @click="toggleMode">
          <div class="slider round"></div>
        </label>

      </div>
    </div>

</template>

<script>


export default {
  data() {
    return {
      isDarkMode: false, // Default mode is Light
    };
  },
  methods: {
    toggleMode() {
      const currentDarkModeState = this.$store.state.darkMode;
      this.$store.commit('darkModeState', !currentDarkModeState);

      this.isDarkMode = currentDarkModeState

      localStorage.setItem("isDark",!currentDarkModeState)
    },
  },
  mounted() {
    this.isDarkMode = !this.$store.state.darkMode;
  },
};
</script>

<style scoped>
body {
  text-align: center;
  background: #51C3A0;
  padding: 50px
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #101010;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
